.cs-dancing-font {
  font-family: "Dancing Script", cursive; 
}
/* .cs-dancing-font {
  font-family: "Spicy Rice", serif;
  font-style: normal;
} */

div, p {
  font-family: 'Roboto', 'Helvetica Neue', HelveticaNeue, sans-serif;
}

.cs-fs-20{
  font-size: 20px;
}

.cs-fs-25{
  font-size: 25px;
}

.cs-fs-14{
  font-size: 14px;
}

.cs-fs-16{
  font-size: 16px;
}

.cs-fs-18{
  font-size: 18px;
}

.cs-fs-30{
  font-size: 30px;
}

.cs-fs-40{
  font-size: 40px;
}


.cs-fw-600{
  font-weight: 600;
}

.cs-fw-700{
  font-weight: 700;
}

.cs-fs-35{
  font-size: 35px;
}