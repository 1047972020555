.slick-next {
  display: none !important;
}

.cs-right-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  padding: 10px;
  border-radius: 50%;
  background-color: #ffb12ade;
  z-index: 1;
}

.cs-left-arrow {
  position: absolute;
  left: 10px;
  top: 50%;
  padding: 10px;
  border-radius: 50%;
  background-color: #ffb12ade;
  z-index: 1;
}

.cs-slider-container {
  position: relative;
}

.cs-cover-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensuring the overlay covers the entire width */
  height: 100%; /* Ensuring the overlay covers the entire height */
  background-color: rgba(0, 0, 0, 0.251);
}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
  .cs-slider-title-txt {
    position: absolute;
    font-size: 1rem;
    color: #fff;
    font-weight: 700;
    right: 20px;
    padding: 5px;
    /* background: rgb(255, 255, 255); */
    top: 30%;
    z-index: 2;
  }
  
  .cs-slider-sub-title-txt{
    position: absolute;
    font-size: 1.5rem;
    color: #ffb12a;
    font-weight: 700;
    right: 10px;
    padding: 5px;
    /* background: rgb(255, 255, 255); */
    top: 10%;
    z-index: 2;
  }
}

/* Media query for desktop devices */
@media only screen and (min-width: 768px) {
  .cs-slider-title-txt {
    position: absolute;
    font-size: 4rem;
    color: #fff;
    font-weight: 700;
    right: 20px;
    padding: 5px;
    /* background: rgb(255, 255, 255); */
    top: 22%;
    z-index: 2;
  }

  .cs-slider-sub-title-txt {
    position: absolute;
    font-size: 2.5rem;
    color: #ffb12a;
    font-weight: 700;
    right: 10px;
    padding: 5px;
    /* background: rgb(255, 255, 255); */
    top: 36%;
    z-index: 2;
  }
}
