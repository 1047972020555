.slick-next {
  display: none !important;
}

.cs-right-arrow {
  position: absolute;
  right: 10px;
  top: 46%;
  padding: 10px;
  border-radius: 50%;
  background-color: #ffb12ade;
  z-index: 1;
}

.cs-left-arrow {
  position: absolute;
  left: 10px;
  top: 46%;
  padding: 10px;
  border-radius: 50%;
  background-color: #ffb12ade;
  z-index: 1;
}

.cs-slider-container {
  position: relative;
}

.cs-testi-img{
  border-radius: 50%;
}