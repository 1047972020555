/* TOP */
.cs-tm-2 {
  margin-top: 2px;
}

.cs-tm-5 {
  margin-top: 5px;
}

.cs-tm-20 {
  margin-top: 20px;
}

.cs-tm-10 {
  margin-top: 10px;
}

.cs-m-5 {
  margin: 5px;
}

.cs-mb-10{
  margin-bottom: 10px;
}

.cs-tm-30 {
  margin-top: 30px;
}

.cs-tm-40 {
  margin-top: 40px;
}

.cs-lm-15 {
  margin-left: 15px;
}

.cs-lm-10 {
  margin-left: 10px;
}

.cs-tm-25 {
  margin-top: 25px;
}

.cs-bm-20 {
  margin-bottom: 20px;
}

/* RIGHT */
.cs-rm-5 {
  margin-right: 5px;
}

.cs-rm-10 {
  margin-right: 10px;
}

.cs-ml-25 {
  margin-left: 25px !important;
}

.cs-mr-25 {
  margin-right: 25px !important;
}

.cs-ml-30 {
  margin-left: 30px !important;
}

.cs-mr-30 {
  margin-right: 30px !important;
}
