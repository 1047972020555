.product-header{
  background-color: #000;
}

.cs-p-10{
  padding: 10px;
}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
.cs-products-flex-direction{
  flex-direction: column-reverse;
}
}

/* Media query for desktop devices */
@media only screen and (min-width: 768px) {
  .cs-products-flex-direction{
  flex-direction: row;
  }
}
