.cs-pc-container {
  border: 1px solid rgba(148, 148, 148, 0.145);
  border-radius: 4px;
  position: relative;
}

.cs-txt-pc {
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cs-pd-pricing {
  color: #ffb12a;
  font-weight: 700;
}

.cs-pd-mrp {
  color: #3434348f;
  font-weight: 700;
  text-decoration: line-through;
}

.cs-pc-footer {
  margin: 20px;
}

/* */

.cs-pc-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.cs-pc-middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.cs-pc-container:hover .cs-pc-image {
  opacity: 0.4;
}

.cs-pc-container:hover .cs-pc-middle {
  opacity: 1;
}

.cs-pc-img-btn {
  background-color: #ffb12a;
  color: white;
  padding: 16px 32px;
  border-radius: 20px;
}
