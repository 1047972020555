.cs-blog-card-title-txt {
  position: absolute;
  font-size: 20px;
  color: #000;
  font-weight: 400;
  left: 20px;
  padding: 5px;
  background: rgb(255, 255, 255);
  bottom: 5%;
  z-index: 2;
}

.cs-blog-container {
  padding: 10px;
}


.cs-blgc-image-container {
  border-radius: 4px;
  overflow: hidden; /* Hide overflow when image is scaled */
  position: relative;
}

.cs-blgc-image-container img {
  transition: transform 0.5s ease; /* Smooth transition */
}

.cs-blgc-image-container:hover img {
  transform: scale(1.2); /* Zoom in on hover */
}