/* Default style for both content */
.cs-content {
  display: none; /* Hide content by default */
}

/* Show content on mobile devices */
.cs-show-mobile {
  display: none; /* Hide content by default */
}

/* Show content on desktop devices */
.cs-show-desktop {
  display: none; /* Hide content by default */
}

.cs-tm-40-in-mob {
  margin-top: 0px;
}


/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
  .cs-show-mobile {
    display: block; /* Show content for mobile devices */
  }
  .cs-tm-40-in-mob {
    margin-top: 40px;
  }
}

/* Media query for desktop devices */
@media only screen and (min-width: 768px) {
  .cs-show-desktop {
    display: block; /* Show content for desktop devices */
  }
  .cs-tm-40-in-mob {
    margin-top: 0px;
  }
}
