.cs-fc-image-container {
  border-radius: 4px;
  overflow: hidden; /* Hide overflow when image is scaled */
  position: relative;
}

.cs-fc-image-container img {
  transition: transform 0.5s ease; /* Smooth transition */
}

.cs-fc-image-container:hover img {
  transform: scale(1.2); /* Zoom in on hover */
}

.cs-title-txt {
  position: absolute;
  font-size: 20px;
  color: #ffb12aec;
  font-weight: 700;
  right: 20px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.867);
  border-radius: 8px;
  top: 20%;
  z-index: 2;
}

.cs-sn-btn {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  color: #000;
  z-index: 2;
  background-color: #ffb12a !important;
}

.cs-sn-btn:hover {
  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  color: #000;
  z-index: 2;
  background-color: #000000 !important;
}


/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
  .cs-feature-txt {
    position: absolute;
    font-size: 50px;
    color: #000;
    font-weight: 700;
    right: 60px;
    padding: 10px;
    border-radius: 8px;
    top: 27%;
    z-index: 2;
  }
  
}

/* Media query for desktop devices */
@media only screen and (min-width: 768px) {
  .cs-feature-txt {
    position: absolute;
    font-size: 50px;
    color: #000;
    font-weight: 700;
    right: 60px;
    padding: 10px;
    border-radius: 8px;
    top: 40%;
    z-index: 2;
  }  
}
