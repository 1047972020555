.cs-nav-grid-container {
  display: grid;
  grid-template-columns: 7% 7% 7% 7%;
}

.cs-nav-grid-container {
  display: grid;
  grid-template-columns: 7% 7% 7% 7%;
}

.cs-info-bar-container {
  background-color: #ffb12a;
  padding: 5px;
}

.cs-currency-dd .ant-select-selector{
  border: none !important;
  border-radius: 15px !important;
}

/* Responsive adjustments */
/* @media screen and (max-width: 768px) {
  .cs-info-bar-container {
    grid-template-columns: 10% 2% 2% 7% 48% 10% 2%;
  }
}

@media screen and (max-width: 576px) {
  .cs-info-bar-container {
    grid-template-columns: 5% 2% 2% 10% 58% 10% 2%;
  }
} */

.cs-social-info-bar-txt {
  word-break: break-all;
  color: #fff;
  font-weight: 700;
  text-align: center;
}
