.cs-slider-thumbnail {
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}
.slick-dots li {
  width: 100px !important; /* Adjust the width as needed */
  height: auto !important; /* Maintain aspect ratio */
}

.slick-dots {
  position: static;
  bottom: -110px !important;
}

.main-product-page{
  min-height: 100vh;
}