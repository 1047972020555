.cs-slider-cover-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Ensuring the overlay covers the entire width */
  height: 100%; /* Ensuring the overlay covers the entire height */
  background-color: rgba(0, 0, 0, 0.721);
}

.cs-slider-img {
  width: 100%;
  height: auto;
}

/* Media query for mobile devices */
@media only screen and (max-width: 767px) {
  .cs-txt-cover-slider {
    font-size: 1.1rem;
    font-weight: 700;
    position: absolute;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ffb12a;
  }

  .cs-bread-crumb-cover-slider {
    font-size: 0.8rem;
    font-weight: 700;
    position: absolute;
    position: absolute;
    left: 50%;
    top: 80%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

/* Media query for desktop devices */
@media only screen and (min-width: 768px) {
  .cs-txt-cover-slider {
    font-size: 3rem;
    font-weight: 700;
    position: absolute;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ffb12a;
  }

  .cs-bread-crumb-cover-slider {
    font-size: 1.5rem;
    font-weight: 700;
    position: absolute;
    position: absolute;
    left: 50%;
    top: 72%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}
