@import "./bootstrap-override.css";
@import "./margin.css";
@import "./custom-responsive.css";
@import "./color.css";
@import "./font.css";

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0px !important;
}

.cs-dis-flex {
  display: flex;
}

.cs-w-100 {
  width: 100%;
}

.cs-height-100{
  height: 100vh;
}

.cs-vt-center {
  display: flex;
  align-items: center;
  align-self: center;
  align-content: center;
}

.cs-hrz-center {
  display: flex;
  justify-content: center;
  justify-items: center;
  justify-self: center;
}

.cs-center {
  display: flex;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-items: center;
  align-self: center;
  align-content: center;
}

.cs-pointer {
  cursor: pointer;
}

.cs-custom-header {
  background: #fff !important;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px !important;
}

.cs-header-container {
  margin: 0px 20px;
}

.txt-align-center {
  text-align: center !important;
}

.cs-txt-nav-header {
  text-align: center;
  font-size: medium;
  font-weight: 600;
}

.cs-logo-mobile{
  width: 50px;
  height: auto;
}

.cs-of-hidden {
  overflow: hidden !important;
}

.cs-desktop-logo{
  width: 64px;
  height: auto;
}

/* .cs-txt-nav-header:hover {
 color: #ffb12ab8;
} */

.cs-flex-end{
  display: flex;
  justify-content: flex-end;
}

.cs-clr-sec{
  color: rgb(110, 110, 110);
}

.cs-image-container {
  border-radius: 4px;
  overflow: hidden; /* Hide overflow when image is scaled */
  position: relative;
}

.cs-image-container img {
  transition: transform 0.3s ease; /* Smooth transition */
}

.cs-image-container:hover img {
  transform: scale(1.1); /* Zoom in on hover */
}

.cs-bg-grey{
  background-color: rgba(201, 201, 201, 0.212);
}

.cs-pos-rel{
  position: relative;
}